/** @jsx createElement */
import { cx } from './utils';
export function createDefaultHeaderComponent(_ref) {
  var createElement = _ref.createElement;
  return function DefaultHeader(props) {
    if (!props.recommendations || props.recommendations.length < 1) {
      return null;
    }

    if (!props.translations.title) {
      return null;
    }

    return createElement("h3", {
      className: cx('auc-Recommend-title', props.classNames.title)
    }, props.translations.title);
  };
}