/** @jsx createElement */
import { cx } from './utils';
export function createListViewComponent(_ref) {
  var createElement = _ref.createElement,
      Fragment = _ref.Fragment;
  return function ListView(props) {
    return createElement("div", {
      className: cx('auc-Recommend-container', props.classNames.container)
    }, createElement("ol", {
      className: cx('auc-Recommend-list', props.classNames.list)
    }, props.items.map(function (item) {
      return createElement("li", {
        key: item.objectID,
        className: cx('auc-Recommend-item', props.classNames.item)
      }, createElement(props.itemComponent, {
        createElement: createElement,
        Fragment: Fragment,
        item: item
      }));
    })));
  };
}